@font-face {
  font-family: 'HelveticaNeue';
  src: local('HelveticaNeue'),
  url(./fonts/HelveticaNeueLTW1G-Roman.woff) format('woff'), 
  url(./fonts/HelveticaNeueLTW1G-Roman.ttf) 
  format('truetype');
}

@font-face {
  font-family: 'HelveticaNeue-Bold';
  src: local('HelveticaNeue-Bold'),
  url(./fonts/HelveticaNeueLTW1G-Bd.woff) format('woff'), 
  url(./fonts/HelveticaNeueLTW1G-Bd.ttf) 
  format('truetype');
}

body {
  font-family: 'HelveticaNeue', Helvetica, Arial, sans-serif;
}

body strong {
  font-family: 'HelveticaNeue-Bold', Helvetica, Arial, sans-serif;
  font-weight: 700;
}
